import React, {useState, useEffect} from 'react';
import {Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import {useFormContext,useForm} from 'react-hook-form';

/*
const accessorySchema = yup.object().shape({
	test: yup.string().required('comon'),
	accessories: yup.array().of(
		yup.object().shape({
			desc: yup.string().required('Please enter accessory'),
			value: yup.string().required('Please enter value')
		})
	)
});
*/
const Accessories = () => {
	const {
		register,
		unregister,
    setValue,
		formState: {errors}
	} = useFormContext() || useForm();

	const [indexes, setIndexes] = useState([0]);
	const [counter, setCounter] = useState(1);

	const addAccessory = () => {

    setIndexes((prevIndexes) => [...prevIndexes, counter]);
		setCounter((prevCounter) => prevCounter + 1);
	};

	const removeAccessory = (index) => {
		setIndexes((prevIndexes) => [
			...prevIndexes.filter((item) => item !== index)
		]);
		unregister(`accessories[${index}].desc`);
		unregister(`accessories[${index}].value`);
	};


	useEffect(() => {
		//console.log(accessories);
	}, []);

	return (
		<>
			<p>List the non-standard accessories and modifications, individually</p>
      
      {indexes.map((index) => {
				const fieldName = `accessories[${index}]`;
				register(`${fieldName}.desc`);
				register(`${fieldName}.value`);
        
        let descInvalid = false;
        let valueInvalid = false;

        if(errors.accessories && errors.accessories[`${index}`] !== undefined) {
          descInvalid = errors.accessories[`${index}`].desc!==undefined
          valueInvalid = errors.accessories[`${index}`].value!==undefined
        }

				return (
					<Row className='mb-3' key={fieldName}>
						<Form.Group as={Col} controlId={`${fieldName}.desc`}>
							<Form.Control
								type='Text'
								name={`${fieldName}.desc`}
                onChange={(e)=>setValue(`${fieldName}.desc`, e.target.value)}
                isInvalid={descInvalid}
							/>
							{descInvalid && (
                <Form.Control.Feedback type='invalid'>
                  {errors.accessories[index]?.desc.message}
                </Form.Control.Feedback>
              )}
						</Form.Group>

						<Form.Group as={Col} lg={2} controlId={`${fieldName}.value`}>
							<InputGroup>
								<InputGroup.Text id='currency-addon'>$</InputGroup.Text>
								<Form.Control
									aria-describedby='currency-addon'
									name={`${fieldName}.value`}
									type='Number'
                  onChange={(e)=>setValue(`${fieldName}.value`, e.target.value)}
									isInvalid={valueInvalid}
								/>
								{valueInvalid && (
									<Form.Control.Feedback type='invalid'>
										{errors.accessories[`${index}`].value.message}
									</Form.Control.Feedback>
								)}
                {
                  
                }

							</InputGroup>
						</Form.Group>
						<Col>
            {indexes[0] !== index &&
            	<Button onClick={() => removeAccessory(index)}>Remove</Button>
            }
						</Col>
					</Row>
				);
			})}

			<Row className='mb-5'>
				<Col>
					<Button onClick={addAccessory}>Add more</Button>
				</Col>
			</Row>
		</>
	);
};

export default Accessories;
